import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, 
         HttpErrorResponse,  HttpProgressEvent,
         HttpHeaderResponse, HttpResponse, 
         HttpUserEvent, HttpSentEvent } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { catchError,  switchMap, finalize, filter, take } from 'rxjs/operators';

// Services
import { UserService } from '../services/security/user.service';
import { RegreshToken } from 'app/models/refreshToken.model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenService implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  skippUrls = [
    '/auth/login',
  ];

  constructor( private _userService : UserService ) { }

  // tslint:disable-next-line:max-line-length
  intercept( req: HttpRequest<any>, next: HttpHandler) : Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    if ( req.url.indexOf( this.skippUrls[0] ) > -1 ) {
      return next.handle( req );
    } else {
      return next.handle(this.addTokenToRequest(req, this._userService.getAccessToken()))
      .pipe(
        catchError( err => {
          if (err instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>err).status) {
              case 401:
                return this.handleUnauthorizedError(req, next);
              default:
                  return throwError( err);
            }
          } else {
            return throwError( err);
          }
        }));

    }


  
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {

    if(!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this._userService.refreshToken()
        .pipe(
          switchMap( (data: RegreshToken ) => {
            if ( data ) {
              this.tokenSubject.next(data.accessToken);
              return next.handle(this.addTokenToRequest(request, data.accessToken));
            }
            return <any>this._userService.logOut();
          }),
          catchError( err => {
            return <any>this._userService.logOut();
          }),
          finalize( ( ) => {
            this.isRefreshingToken = false;
          })
        );
    } else {

      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        }));
    }
  }

}
