import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.prod';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

//Model
import { Dataload } from '../../../models/dataload.model';
import { DataloadConavi } from '../../../models/dataloadConavi.model';

@Injectable({
  providedIn: 'root'
})
export class DataloadService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<Dataload[]>{
    const url = `${environment.APIEnpoint}/api/ETL/executeetlprocess`;
    return this.http.get(url).pipe(
      map((res:any)=>{
        const dataloads: Dataload[]=[];

        if (res.data) {
          res.data.forEach(d=>{
          const dataload = new Dataload(
          d.idprocessbinnacle,
          d.procesogeneral,
          d.proceso,
          d.fechainicio,
          d.fechatermino,
          d.estatus,
          d.errordescription);
          dataloads.push(dataload);
          });
        }
        return dataloads;

      }),
      catchError((err) => {
        return throwError(err);
      })
    )
  
  }

  public getDataloadConavi(): Observable<DataloadConavi[]>{
    const url = `${environment.APIEnpoint}/api/ETL/executejsonprocess`;
    return this.http.get(url).pipe(
      map((res:any)=>{
        const dataloads: DataloadConavi[]=[];

        if (res.data) {
          res.data.forEach(d=>{
          const dataload = new DataloadConavi(
          d.id,
          d.process,
          d.subprocess,
          d.startdate,
          d.enddate,
          d.status,
          d.errordescription);
          dataloads.push(dataload);
          });
        }
        return dataloads;

      }),
      catchError((err) => {
        return throwError(err);
      })
    )
  
  }

}
