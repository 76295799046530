import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Envarroment
import { environment } from 'environments/environment';

// Models
import { PerformanceAnalysis } from 'app/models/performance-analysis.mode';

@Injectable({
  providedIn: 'root'
})
export class PerformanceAnalysisService {

  constructor( private  http: HttpClient ) { }

  getAll( ): Observable<PerformanceAnalysis[]> {
    const url = `${environment.APIEnpoint}/api/PerformanceAnalysis`;

   return this.http.get( url ).pipe( map( ( res: any ) => {
         const data: PerformanceAnalysis[] = [];

         if( res.data ) {
           res.data.forEach( element => {
              const analysis = new PerformanceAnalysis(
                element.id,
                element.feedBack,
                element.userId,
                element.dashboardId,
                element.kpiTrackId,
                element.active,
                element.registerDate
               );
               data.push(analysis);
           });
         }
         return data;
    }),
    catchError((err) => {
      return throwError(err);
     })
    );

  }

  getByKpiTack( calendarId: number, kpiId: number ): Observable<PerformanceAnalysis[]> {
    const url = `${environment.APIEnpoint}/api/PerformanceAnalysis/getperformanceanalisis`;

    const params = {
      calendarId: calendarId ,
      kpiId: kpiId
    };

   return this.http.post( url, params ).pipe( map( ( res: any ) => {
         const data: PerformanceAnalysis[] = [];

         if( res.data ) {
           res.data.forEach( element => {
              const analysis = new PerformanceAnalysis(
                element.id,
                element.feedBack,
                element.userId,
                element.dashboardId,
                element.kpiTrackId,
                element.active,
                element.registerDate
               );
               data.push(analysis);
           });
         }
         return data;
    }),
    catchError((err) => {
      return throwError(err);
     })
    );

  }

  delete( id: number): Observable<boolean> {
    const url = `${environment.APIEnpoint}/api/PerformanceAnalysis/${id}`;

    return this.http.delete(url).pipe( map( (res: any) => {
      return res.success;
      }),
      catchError((err) => {
        return throwError(err);
      })
      );
  }

  save ( feedBack: PerformanceAnalysis  ): Observable<boolean> {
    const url = `${environment.APIEnpoint}/api/PerformanceAnalysis`;
    const data = {
      feedBack: feedBack.feedBack,
      dashboardId: feedBack.dashboardId,
      kpiTrackId: feedBack.kpiTrackId,
      userId: feedBack.userId,
      active: true
    };

    return this.http.post(url,data).pipe( map( (res: any) => {
        return res.success;
    }),
    catchError((err) => {
      return throwError(err);
     })
    );
  }

}
