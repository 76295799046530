export class ProposalType {

    static deserialize(o: any): ProposalType {
        return new ProposalType(
            o['id'],
            o['name']
        );
    }

    constructor(
        public id: number,
        public name: string
    ) {}
}
