import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Initiativesheet, InitiativesheetActivity, InitiativesheetKpi } from 'app/models/initiativesheet.model';

@Injectable({
  providedIn: 'root'
})
export class InitiativesheetService {

  constructor(private http: HttpClient) { }


  public getAllByStrategy(strategyId:number): Observable<any>{
    const url =`${environment.APIEnpoint}/api/Reports/${strategyId}/getstrategysheet`;
    return this.http.get(url).pipe(
      map((res:any)=>{

        return new Initiativesheet(
          res.data.name,
          res.data.description,
          res.data.objetiveName,
          res.data.startDate,
          res.data.endDate,
          res.data.resource,
          res.data.responsable.name,
          res.data.responsable.position,
          res.data.responsable.eMail,
          res.data.responsable.extension,
          res.data.responsable.departmentName,

          (res.data.reviewer != null) ? res.data.reviewer.name:null,
          (res.data.authorizer !=null)? res.data.authorizer.name:null,
          (res.data.reviewer != null) ? res.data.reviewer.position:null,
          (res.data.authorizer !=null)? res.data.authorizer.position:null,
          res.data.estimatedDuration
          
   
        )
      }),
      catchError((err)=>{
        return throwError(err);
      })
    );
  }


public  getAllByActivity( strategyId: number ): Observable<any> {
  const url = `${environment.APIEnpoint}/api/Reports/${strategyId}/getstrategysheet`;
  return this.http.get(url).pipe(map((res:any)=>{
    const activities: InitiativesheetActivity[]=[];

    res.data.activities.forEach(element => {
      activities.push(
        new InitiativesheetActivity(
          element.description
        ));
    });
    return activities;
  }),
  catchError((err)=>{
    return throwError(err);
  })
  
  );    
}

public  getAllByKpi( strategyId: number ): Observable<any> {
  const url = `${environment.APIEnpoint}/api/Reports/${strategyId}/getstrategysheet`;
  return this.http.get(url).pipe(map((res:any)=>{
    const kpis: InitiativesheetKpi[]=[];

    res.data.kpis.forEach(element => {
      kpis.push(
        new InitiativesheetKpi(
          element.name
        ));
    });
    return kpis;
  }),
  catchError((err)=>{
    return throwError(err);
  })
  
  );    
}

}
