import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, MeasureUnitService } from 'app/shared/services/service.index';

import swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('f') loginForm: NgForm;

  landingPage = '/strategy-map';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _userService: UserService,
    private _measureUnitService: MeasureUnitService) { }

  ngOnInit() {}

  // On Forgot password link click
  onForgotPassword() {
    this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
  }

  // On registration link click
  onRegister() {
    this.router.navigateByUrl('/');
  }

  onSubmitLogin( form: NgForm ) {

    const username = form.value.username;
    const password = form.value.password;

    if ( username === '' || password === '' ) {
      swal('Datos no vaidos', 'El campo nombre de usuario y contraseña son obligatorios', 'error');
    }

    this._userService.login(username, password)
      .subscribe(
        ( res ) => {
          this._userService.getMenu().subscribe( ( hasMenu: boolean ) => {
            if ( hasMenu ) {
              this.router.navigate([this.landingPage]);
            } else {
              swal('Problemas al iniciar sesión', 'No tiene opciones de menu asignado' , 'warning')
            }
          });
        },
        ( err: any ) => {
          const errorMessage = err.error != null ?  err.error.message : 'Error al autenticar el usuario';
          swal('Problemas al iniciar sesión', errorMessage , 'warning')
        }
      );
  }

}
