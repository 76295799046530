import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// MODELS
import { Deparment, DepartmentItem } from 'app/models/department.model';
import { User } from 'app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor( private  http: HttpClient ) { }

  // Obtiene todos los departamentos registrados
  public getAll(): Observable<Deparment[]> {
    const url = `${environment.APIEnpoint}/api/Department`;
    return this.http.get(url).pipe(
      map( (res: any) => {
         const departaments: Deparment[] = [];

         if ( res.data ) {
           res.data.forEach( d => {
              const departament = new Deparment(d.id, d.name, d.companyId, d.active);
              departaments.push(departament);
           });
         }
         return departaments;
      }),
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  public getByCompany(companyId: number) {
    const url = `${
      environment.APIEnpoint
    }/api/Department/${companyId}/getwithresponsable`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const departments: DepartmentItem[] = [];
        res.data.forEach(element => {
          departments.push(
            new DepartmentItem(element.name, element.fullName,element.id)
          );
        });
        return departments;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getOrgChartByCompany(companyId: number) {
    const url = `${environment.APIEnpoint}/api/Department/${companyId}/getwithresponsablejs`;

    return this.http.get(url).pipe(
      map((res: any) => {
        const departments: Deparment[] = [];
        res.data.forEach(d => {
          const user = d.user;
          departments.push(
            // tslint:disable-next-line: max-line-length
            new Deparment(d.id, d.name, d.departmentId, null, null, null, { fullName: user.fullName, position: user.position, eMail: user.email })
          );
        });
        return departments;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getById(id: number) {
    const url = `${environment.APIEnpoint}/api/Department/${id}/getbyid`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return new Deparment(
          res.data.id,
          res.data.name,
          res.data.departmentId,
          res.data.companyId,
          res.data.userId,
          res.data.active
        );
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public save(department: Deparment) {
    const url = `${environment.APIEnpoint}/api/Department`;
    const dataRequest = this.getRequestData(department);

    if (dataRequest.id === 0) {
      return this.http.post(url, dataRequest).pipe(map((res: any) => res.data));
    } else {
      return this.http.put(url, dataRequest).pipe(map((res: any) => res.data));
    }
  }

  public delete(id: number) {
    const url = `${environment.APIEnpoint}/api/Department/${id}`;
    return this.http.delete(url);
  }

  private getRequestData(department: Deparment): any {
    return {
      id: department.id,
      name: department.name,
      departmentId: department.departmentId,
      companyId: department.companyId,
      userId: department.userId,
      active:department.active
    };
  }

}
