export const environment = {
  production: true,
  //APIEnpoint: 'http://gcaseqa-001-site49.atempurl.com/webapi', //'http://189.240.106.43/webapi',
  //APIEnpoint: 'http://localhost:5000', //'http://189.240.106.43/webapi',
  APIEnpoint: 'https://apiebsc.stemx.com.mx',
  period: [2019, 2020, 2021, 2022, 2023, 2024],
  goalColor: '#0f312b',
  advanceColor: '#C3945E',
  colors: {
    'Rojo' : '#ff0000',
    'Amarillo': '#ffef47',
    'Verde': '#00ba18'
  }
};
