import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _token: string;

  constructor() {
     this._token = localStorage.getItem('token');
  }

  public getDashboardId() : number {
    return  Number(localStorage.getItem('dashboardId'));
  }

  public getDepartmentId() : number {
     return  Number(localStorage.getItem('departmentId'));
  }

  public getId(): number {
    return  Number(localStorage.getItem('id'));
  }

  public getName(): string {
    return  localStorage.getItem('name');
  }

  public getPosition(): string {
    return  localStorage.getItem('position');
  }

  public  getSecondName(): string {
    return  localStorage.getItem('secondName');
  }

  public  getSecondSurName(): string {
    return  localStorage.getItem('secondSurName');
  }

  public  getSurName(): string {
    return  localStorage.getItem('surName');
  }

  public  getVision(): string {
    return  localStorage.getItem('visionCia');
  }

  public  getCalendarId(): number {
    return  Number(localStorage.getItem('calendarId'));
  }

  public  getRolId(): number {
    return  Number(localStorage.getItem('rolId'));
  }

  public cleanStorage(): void {
    localStorage.clear();
  }

}
