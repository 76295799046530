import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Rxjs
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Environment
import { environment } from 'environments/environment';
// Models
import { ProposalValue } from 'app/models/proposal-value.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalValueService {

  constructor( private http: HttpClient ) { }

  getAllByStrategyMapId( id: number ) {
    const url = `${environment.APIEnpoint}/api/ProposalValue/${id}/getallbystrategymapid`;

    return this.http.get( url ).pipe(
      map((res: any) => {
        const proposalValues: ProposalValue[] = [];

        res.data.forEach( (pv: any) => {
          proposalValues.push(ProposalValue.deserialize(pv));
        });

        return proposalValues;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  addOrUpdate(proposalValue: ProposalValue) {
    const url = `${environment.APIEnpoint}/api/ProposalValue`;

    const dataRequest = this.getRequestData( proposalValue );

    if ( dataRequest.id === 0 ) {
      return this.http.post(url, dataRequest).pipe( map( (res: any) => res.data ), catchError( (err) => { return throwError(err); } ) );
    } else {
      return this.http.put(url, dataRequest).pipe( map( (res: any) => res.data ), catchError( (err) => { return throwError(err); } ) );
    }

  }

  delete(id: number) {
    const url = `${environment.APIEnpoint}/api/ProposalValue/${id}`;

    return this.http.delete(url).pipe( map( (res: any) => res.success ), catchError( (err) => { return throwError(err); } ) );
  }

  private getRequestData( proposalValue: ProposalValue): any {
    return {
      dataValue: proposalValue.dataValue,
      proposalTypeId: proposalValue.proposalTypeId,
      strategyMapId: proposalValue.strategyMapId,
      id: proposalValue.id
    };
  }
}
