export class DataloadConavi{
    constructor(
        public id: number,
        public process: string,
        public subprocess: string,
        public startdate: Date,
        public enddate: Date,
        public status: string,
        public errordescription: string
    ){}
}