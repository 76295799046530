import { Kpi } from './kpi.model';

export class Objective {

    constructor(
        public id: number,
        public name: string,
        public scopeId?: number,
        public startDate?: Date,
        public endDate?: Date,
        public perspectiveId?: number,
        public perspective?: string,
        public objetiveId?: number,
        public active?: boolean,
        public affects?: any[],
        public affectedBy?: any[],
        public kpis?: Kpi[],
        public period?: number,
        public keyName?: string
    ) { }
}
