import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

// Services
import { UserService } from '../services/service.index';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor( private _userService: UserService,
               private _router: Router ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let canAccess = false;
    if ( !this._userService.isAuthenticated() ) {
      this._router.navigateByUrl('/login');
    }

    const permissions = JSON.parse(localStorage.getItem('permissions'));
    permissions.forEach( p => {
      if ( state.url.indexOf(p) > -1 ) {
        canAccess = true;
      }
    });

    if ( canAccess === false ) {
      this._router.navigateByUrl('/deny-access');
    }

    return canAccess;
  }
}
