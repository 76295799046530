import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Scope } from 'app/models/scope.model';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Scope[]> {
    const url = `${environment.APIEnpoint}/api/Scope`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const scope: Scope[] = [];

        res.data.forEach( element => {
          scope.push( new Scope(
            element.id,
            element.name,
            element.active)
          );
        });

        return scope;

      }), catchError((err) => {

        return throwError(err);

      })
    );
  }
}
