import { Component, OnInit, Input } from '@angular/core';
// MODELS
import { Progressbar } from 'app/models/components/progress-bar.model';

@Component({
  selector: 'app-multicolor-progress-bar',
  templateUrl: './multicolor-progress-bar.component.html',
  styleUrls: ['./multicolor-progress-bar.component.scss']
})
export class MulticolorProgressBarComponent implements OnInit {

  @Input('showScale') showScale = true;
  @Input('showValue') showValue = false;

  _data: Progressbar;
  private _endRange: number[] = [];
  private _initRange: number[] = [];
  private _labelColor: string;

  @Input() set data(value: Progressbar) {
    this._data = value;
    this.calculateSizeBar();
  }

  constructor() { }

  ngOnInit() { }

  // Calcular los valores máximos y minimos de los rango para el calculo
  // del tamaño de la barra
  calculateSizeBar( ): void {

    this._endRange = this._data.ranges.map( x => x.endRange );
    this._initRange = this._data.ranges.map( x => x.initRange );

    this._data.maxValue = Math.max(...this._endRange);
    this._data.minValue =  Math.min(...this._endRange);

    this._data.ranges.forEach( range => {
      range.maxValue = this._data.maxValue;
      if( this._data.valueNumber >= range.initRange && this._data.valueNumber <= range.endRange ) {
        this._data.color = range.color;
      }
    });
  }

}
