export class Company {
    constructor(
      public id: number,
      public name: string,
      public mission?: string,
      public ciaValues?: string,
      public vision?: string,
      public logo?: string,
      public active?: boolean
    ) {}
  }
  