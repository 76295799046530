import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { MeasureUnit } from 'app/models/measure-unit.model';

@Injectable({
  providedIn: 'root'
})
export class MeasureUnitService {

  constructor( private http: HttpClient ) { }

  getAll(): Observable<MeasureUnit[]> {
    const url = `${environment.APIEnpoint}/api/MeasureUnit`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const data: MeasureUnit[] = [];

        res.data.forEach( element => {
          data.push( new MeasureUnit(
            element.id,
            element.name )
          );
        });

        return data;

      }), catchError((err) => {

        return throwError(err);

      })
    );
  }

}
