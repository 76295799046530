import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable, empty, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { DashBoard } from 'app/models/dashboard.model';
import { DateUtilities } from 'app/shared/utilities/date.utilitie';
import { CardKpiItem } from 'app/models/components/card-kpi-item.model';
import { CardKpi } from 'app/models/components/card-kpi.model';
import { RangeProgressBar } from 'app/models/components/range-progress-bar.model';
import { Progressbar } from 'app/models/components/progress-bar.model';
import { DashboardFilters } from 'app/models/components/dashboard-filters.model';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor( private  http: HttpClient ) {}

	// Obtener el listado de todos los tableros registrados
	public getAll(): Observable<DashBoard[]> {
		const url = `${environment.APIEnpoint}/api/Dashboard`;
		return this.http.get(url).pipe(
			map((res: any) => {
				const dashboards: DashBoard[] = [];

				res.data.forEach((d) => {
					dashboards.push(
						new DashBoard(
							d.dashboardId,
							d.name,
							d.description,
							d.isPublic,
							new Date(d.startDate),
							new Date(d.endDate),
							d.userId,
							d.companyId,
							d.active
						)
					);
				});
				return dashboards;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	// Obtener los datos d eun tablero por identificador
	public getById( id: number): Observable<DashBoard> {
		const url = `${environment.APIEnpoint}/api/Dashboard/${id}/getbyid`;
		return this.http.get(url).pipe( map( (res: any) => {
									return new DashBoard(
										res.data.dashboardId,
										res.data.name,
										res.data.description,
										res.data.isPublic,
										new Date(res.data.startDate),
										new Date(res.data.endDate),
										res.data.userId,
										res.data.companyId,
										res.data.active
									);
								}),
								catchError((err) => {
								return throwError(err);
								})
							);
	}

	// Agregar un nuevo tablero o actualizar los datos de un tablero existente
	public save( dashboard: DashBoard ): Observable<DashBoard> {
		const url = `${environment.APIEnpoint}/api/Dashboard`;
		const dataRequest = this.getRequestData( dashboard );

		if ( dataRequest.dashboardId === 0 ) {
			return this.http.post(url, dataRequest).pipe(
				map( (res: any) => this.setDashboardData(res) )
			 );
		} else {
			return this.http.put(url, dataRequest).pipe(
				map( (res: any) => this.setDashboardData(res) )
				 );
		}
	}

	// Eliminar un tablero existente
	public delete( id: number ) {
		const url = `${environment.APIEnpoint}/api/Dashboard/${id}`;
		return this.http.delete(url);
	}

	// Agregar un nuevo kpi al tablero
	public addKpi( kpi: number, dashboardId: number ) {
		const url = `${environment.APIEnpoint}/api/KpiDashboard/`;
		const request = {
			kpiId: kpi,
			dashboardId : dashboardId
		};

		return this.http.post(url, request).pipe( map( (res: any) => console.log(res)  ) );
	}

	// Obtener el listado de tableros y kpis
	public getDashboardJs( month: string, year: string, dashboardId: number, departmentId: number ): Observable<any>  {

		const url = `${environment.APIEnpoint}/api/Dashboard/getdashboarjs`;
		const request = {
			month : month,
			year: year,
			dashboardId: dashboardId ,
			departmentId: departmentId === 0 ? null : departmentId
		};

		return this.http.post(url, request).pipe( map(
			(res: any) => {
				const cards: CardKpi[] = [];
				const perspectives = res.data.perspectives;

				if ( perspectives ) {
					perspectives.forEach( ( p: any )  => {

						const items: CardKpiItem[] = [];
						const kpisArray =  p.kpis;

						if ( p.kpis ) {

							kpisArray.forEach( ( kpi: any ) => {

								const ranges: RangeProgressBar[] = [];
	  						    const planned  =  kpi.planned ? kpi.planned : 0;
								const real  =  kpi.real ? kpi.real : 0;
								const performance  =  kpi.performance ? kpi.performance : 0;
								const progressBar = new Progressbar('', real, ranges, '#023F76');
								const objectiveKey = kpi.keyName.split('.')[0];

								const item = new CardKpiItem( kpi.name, progressBar , planned, real, performance,
															  kpi.keyName, kpi.kpiId, kpi.measureUnitId, kpi.objetiveName,
															  kpi.color, kpi.objetiveId, objectiveKey );

								item.FrecuencyName = kpi.frecuencyName;

								items.push(item);
							});

							const perspetive = new CardKpi(p.name, p.percent, items);
							cards.push(perspetive);
						}
					});
				}
				const dashboardFilters: DashboardFilters = new DashboardFilters(res.data.percent, cards);
				return dashboardFilters;
			}),
			catchError(this.errorHandler.bind(this) ));
	}

	public getDashboardJsForMap( month: string, year: string, dashboardId: number, departmentId: number ): Observable<any>  {
		const url = `${environment.APIEnpoint}/api/Dashboard/getdashboarjs`;
		const request = {
			month : month,
			year: year,
			dashboardId: dashboardId,
			departmentId: null
		};

		return this.http.post(url, request).pipe( map(
			(res: any) => {
				return res.data;
			}),
			catchError(this.errorHandler.bind(this) ));
	}

	// Actualizar estatus a publico o privado de un dashboard existente
	public updatToPublic( id: number) {
		const url = `${environment.APIEnpoint}/api/Dashboard/dodashboardpublicbyid?id=${ id }`;
		return this.http.post( url, { } ).pipe( map( (res: any) => true ) , catchError( (err) => { return throwError(err); } ) );
	}

	private setDashboardData( res: any ): DashBoard {

		return new DashBoard(
			res.data.dashboardId,
			res.data.name,
			res.data.description,
			res.data.isPublic,
			new Date(res.data.startDate),
			new Date(res.data.endDate),
			res.data.userId,
			res.data.companyId,
			res.data.active
		);

	}

	private getRequestData( dashboard: DashBoard): any {

		return {
			name: dashboard.name,
			description: dashboard.description,
			isPublic: dashboard.isPublic,
			endDate: DateUtilities.dateSerialize(dashboard.end),
			startDate:  DateUtilities.dateSerialize(dashboard.start),
			userId: dashboard.userId,
			companyId : dashboard.companyId,
			dashboardId : dashboard.id,
			active: dashboard.active
		};
	}

	 /** Error Handling method */
	errorHandler(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {		  // A client-side or network error occurred. Handle it accordingly.
		  console.error('An error occurred:', error.error.message);
		} else {
		  console.error(
			`Backend returned code ${error.status}, ` +
			`body was: ${error.error}`);
		}
		return throwError(error.error);
	}
}