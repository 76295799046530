import { CardKpiItem } from './card-kpi-item.model';

export class CardKpi {
    constructor (
        public title: string,
        public advance: number,
        public items?: CardKpiItem[] ,
        public color?: string       
    ) { 
    
    }
}
