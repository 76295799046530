import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

// RXJS
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { User } from 'app/models/user.model';
import { Deparment } from 'app/models/department.model';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor( private http: HttpClient,
    		   private router: Router) { }
    

    getUsers(): Observable<User[]>{
      let users:User[]=[]
      const url = `${environment.APIEnpoint}/api/User`;
      return this.http.get(url)
			.pipe(
				map((res: any) => {
					const users: User[] = [];
					res.data.forEach(element => {
						
						users.push(new User(
							element.id,
							element.userName,
							element.name,
							element.secondName,
							element.surName,
							element.secondSurName,
							element.telephone,
							element.cellPhone,
							element.extension,
							element.eMail, 
							element.position,
							element.departmentId,
							element.active,
							element.password,
							(element.department && element.department.name) ? element.department.name:""
						   //(element.department.name != null) ? element.department.name : null,

							
							
							));
					});
					return users;
				})
			);
}

getUserById(id): Observable<User[]>{
	const url = `${environment.APIEnpoint}/api/User/` + id + `/getbyid`;
	return this.http.get(url)
		  .pipe(
			  map((res: any) => {
				  return res;
			  })
		  );
}



public getAllByDepartment(id:number): Observable<User[]>{
	const url = `${environment.APIEnpoint}/api/User/` +id+ `/getallbydepartmentid`;
	return this.http.get(url).pipe(map((res:any)=>{
		const users: User[]=[]
		res.data.forEach(element=>{
			users.push(new User(
				element.id,
				element.userName,						
				element.name,
			    element.secondName,
				element.surName,						
				element.surSecondName,						
				element.telePhone, 						
				element.cellPhone,
				element.extension,						
				element.eMail,						
				element.position,
				element.departmentId,						
				element.active,
			    element.password));
		});
		return users;
	})
	);
}



public save(user: User) {
    const url = `${environment.APIEnpoint}/api/User`;
      return this.http.put(url, user).pipe(map((res: any) => res.data));
      
	}
	
  }

