import { Routes, RouterModule } from '@angular/router';


export const DASHBOARD_ROUTES: Routes = [
    {
        path: 'dashboards',
        loadChildren:  './pages/dashboards/dashboards.module#DashboardPagesModule'
      }
];

