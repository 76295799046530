// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //APIEnpoint: 'http://gcaseqa-001-site29.atempurl.com/webapi',
  //APIEnpoint: 'http://localhost:5000',
  APIEnpoint: 'https://apiebsc.stemx.com.mx',
  period: [2019, 2020, 2021, 2022, 2023, 2024],
  goalColor: '#0f312b',
  advanceColor: '#C3945E',
  colors: {
    'Rojo' : '#ff0000',
    'Amarillo': '#ffef47',
    'Verde': '#00ba18'
  }
};
