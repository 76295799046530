import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Kpi } from 'app/models/kpi.model';
import { KpiRange } from 'app/models/kpi-range.model';
import { Deparment } from 'app/models/department.model';
import { KpiLight } from 'app/models/kpi.model';


@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor( private  http: HttpClient ) { }

  getAll( ): Observable<Kpi[]> {
    const url = `${environment.APIEnpoint}/api/kpi`;
    return this.http.get( url ).pipe(
      map( (res: any) => {
        const kpis: Kpi[] = [];
        res.data.forEach( (kpi: any) => {
          const department = kpi.department ? new Deparment( kpi.department.id, kpi.department.name ) : null;
          kpis.push(
            new Kpi(
              kpi.kpiId,
              kpi.name,
              kpi.keyName,
              kpi.description,
              kpi.minValue,
              kpi.maxValue,
              kpi.weight,
              kpi.dataSource,
              kpi.polarity,
              kpi.order,
              new Date(kpi.startDate),
              new Date(kpi.endDate),
              kpi.measureTypeId,
              kpi.measureUnitId,
              kpi.frecuencyId,
              kpi.userId,
              kpi.strategyId,
              kpi.operativeGroupId,
              '',
              0,
              kpi.isPlanned,
              department
            )
          );
        });
        return kpis;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAllLight( ): Observable<KpiLight[]> {

    const url = `${environment.APIEnpoint}/api/kpi/getalllight`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        return res.data.map( (kpi: any) => KpiLight.deserialize(kpi) );
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getByResponsable( userId: number, year:number ): Observable<Kpi[]>  {

    const url = `${environment.APIEnpoint}/api/Kpi/getallbyresponsableid`;
    const paramas = {
      userId: userId,
      year: year
    }
    return this.http.post( url, paramas ).pipe(
      map( (res: any) => {
        const kpis: Kpi[] = [];
        res.data.forEach( (kpi: any) => {
          const department = kpi.department ? new Deparment( kpi.department.id, kpi.department.name ) : null;
          kpis.push(
            new Kpi(
              kpi.kpiId,
              kpi.name,
              kpi.keyName,
              kpi.description,
              kpi.minValue,
              kpi.maxValue,
              kpi.weight,
              kpi.dataSource,
              kpi.polarity,
              kpi.order,
              kpi.startDate,
              kpi.endDate,
              kpi.measureTypeId,
              kpi.measureUnitId,
              kpi.frecuencyId,
              kpi.userId,
              kpi.strategyId,
              kpi.operativeGroupId,
              '',
              0,
              kpi.isPlanned,
              department
            )
          );
        });
        return kpis;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getByObjetive( objetiveId: number ): Observable<Kpi[]> {
    const url = `${environment.APIEnpoint}/api/kpi/${objetiveId}/getallbyobjetiveid`;
    return this.http.get( url ).pipe(
      map( (res: any) => {
        const kpis: Kpi[] = [];

        res.data.forEach(element => {
            kpis.push( new Kpi(
              element.kpiId,
              element.name
            ));
        });

        return kpis;

      }), catchError((err) => {
        return throwError(err);
        })
      );
  }

  // GET ALL BY DEPARTMENT
  getByDepartment( departmentId: number ): Observable<Kpi[]> {
    const url = `${environment.APIEnpoint}/api/kpi/${departmentId}/getallbydepartmentid`;
    return this.http.get( url ).pipe(
      map( (res: any) => {
        const kpis: Kpi[] = [];

        res.data.forEach(element => {
          const department = element.department ? new Deparment( element.department.id, element.department.name ) : null;
            kpis.push( new Kpi(
            element.kpiId,
            element.name,
            element.keyName,
            element.description,
            element.minValue,
            element.maxValue,
            element.weight,
            element.dataSource,
            element.polarity,
            element.order,
            element.startDate,
            element.endDate,
            element.measureTypeId,
            element.measureUnitId,
            element.frecuencyId,
            element.userId,
            element.strategyId,
            element.operativeGroupId,
            '',
            0,
            element.isPlanned,
            department
            ));
        });
        // console.log("prueba", kpis);

        return kpis;

    }), catchError((err) => {
      return throwError(err);
      })
    );
  }

  getByDashBoard( dashboardId: number ): Observable<Kpi[]>  {
    const url = `${environment.APIEnpoint}/api/kpi/${dashboardId}/getallbydashboardid`;
    return this.http.get( url ).pipe(map( (res: any) => {
      const kpis: Kpi[] = [];

      res.data.forEach(element => {
         kpis.push( new Kpi(
           element.kpiId,
           element.name
         ));
      });

      return kpis;

  }), catchError((err) => {
    return throwError(err);
   })
  );
  }

  deleteFromDashboard( dashboardId: number, kpiId: number ) {
    const url = `${environment.APIEnpoint}/api/KpiDashboard`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        kpiId: kpiId,
        dashboardId: dashboardId
      }
    };

    return this.http.delete(url, options);
  }

  // Guardar un nuevo indicador o actualizar los datos d eun existente
  save( kpi: any ): Observable<Kpi> {

    const url = `${environment.APIEnpoint}/api/Kpi`;

    if ( kpi.id === 0 ) {
      return this.http.post(url, kpi).pipe(
          map( (res: any) =>  this.setKpiData( res ))
        , catchError((err) => {
           return throwError(err);
          }) );
    } else {
      kpi.kpiId = kpi.id;
      return this.http.put(url, kpi).pipe(
       map( (res: any) =>  this.setKpiData( res ) )
      , catchError((err) => {
          return throwError(err);
       }) );
    }

  }

  // Obtener el KPI por identificador
  getById( id: number ): Observable<Kpi> {

    const url = `${environment.APIEnpoint}/api/Kpi/${id}/getbyid`;
    return this.http.get(url).pipe( map( (res: any ) => {

      const kpi: Kpi = new Kpi(0, '');

      if ( res.data ) {

        const data = res.data;

        kpi.id = data.kpiId;
        kpi.dataSource  = data.dataSource;
        kpi.description = data.description;
        kpi.endDate = new Date(data.endDate);
        kpi.formula = data.formula;
        kpi.frecuencyId = data.frecuencyId;
        kpi.keyName = data.keyName;
        kpi.maxValue = data.maxValue;
        kpi.measureTypeId = data.measureTypeId;
        kpi.measureUnitId = data.measureUnitId;
        kpi.minValue = data.minValue;
        kpi.name = data.name;
        kpi.order = data.order;
        kpi.polarity = data.polarity;
        kpi.startDate = new Date(data.startDate);
        kpi.strategyId = data.strategyId;
        kpi.userId = data.userId;
        kpi.weight = data.weight;
        kpi.department = new Deparment(res.data.departmentId, '');
        kpi.valueLB = data.valueLB;
        kpi.yearLB = data.yearLB;
        kpi.periodLB = data.periodLB;
        kpi.valueMA = data.valueMA;
        kpi.yearMA = data.yearMA;
        kpi.periodMA = data.periodMA;
        kpi.variable1 = data.variable1;
        kpi.description1 = data.description1;
        kpi.source1Id = data.source1Id;
        kpi.variable2 = data.variable2;
        kpi.description2 = data.description2;
        kpi.source2Id = data.source2Id;
        kpi.authorizerId = data.authorizerId;
        kpi.reviewerId = data.reviewerId;
        kpi.calculationId = data.calculationId;
        kpi.goalFinish = data.goalFinish;
        kpi.decimalNumber = data.decimalNumber;
        kpi.anotherSource = (data.anotherSource) ? data.anotherSource : "";
        

      }
      console.log(kpi);
      return kpi;
    }),
      catchError((err) => {
       return throwError(err);
      })
    );
  }

  /* RANGE */
  getRangeByKpiId( id: number ) {
    const url = `${environment.APIEnpoint}/api/KpiRange/${id}/getallbykpiid`;
    return this.http.get( url ).pipe( map( ( res: any ) => {

      const ranges: KpiRange[] = [];

      if ( res.data ) {
        res.data.forEach( r => {
           const range = new KpiRange( r.id, r.startRange, r.endRange, r.color, r.kpiId )
          range.hexaCodeColor = environment.colors[range.colorName];
           range.startLogic = r.startLogic === null ? '' : r.startLogic; 
           range.endLogic = r.endLogic === null ? '' : r.endLogic; 

           ranges.push( range );
        });
      }

      return ranges;
    }),
      catchError((err) => {
        return throwError(err);
     })
    );
  }

  // Guardar y actualizar
  saveRange( kpiRange: any ) {
    const url = `${environment.APIEnpoint}/api/KpiRange`;
    if ( kpiRange.id === 0 ) {
      return this.http.post( url, kpiRange ).pipe( map( ( res: any ) =>  true ));
    } else {
      return this.http.put( url, kpiRange ).pipe( map( ( res: any ) =>  true ));
    }
  }

  // Eliminar un rango de un indicador
  deleteRange( id: number ) {
    const url = `${environment.APIEnpoint}/api/KpiRange/${id}`;
    return this.http.delete( url ).pipe( map( ( res: any ) =>  true ));
  }

  private setKpiData( res: any): Kpi {

    const kpiInsert: Kpi = new Kpi(res.data.kpiId, '');

    kpiInsert.id = res.data.kpiId;
    kpiInsert.dataSource  = res.data.dataSource;
    kpiInsert.description = res.data.description;
    kpiInsert.endDate = new Date(res.data.endDate);
    kpiInsert.formula = res.data.formula;
    kpiInsert.frecuencyId = res.data.frecuencyId;
    kpiInsert.keyName = res.data.keyName;
    kpiInsert.maxValue = res.data.maxValue;
    kpiInsert.measureTypeId = res.data.measureTypeId;
    kpiInsert.measureUnitId = res.data.measureUnitId;
    kpiInsert.minValue = res.data.minValue;
    kpiInsert.name = res.data.name;
    kpiInsert.order = res.data.order;
    kpiInsert.polarity = res.data.polarity;
    kpiInsert.startDate = new Date(res.data.startDate);
    kpiInsert.strategyId = res.data.strategyId;
    kpiInsert.userId = res.data.userId;
    kpiInsert.weight = res.data.weight;

    return kpiInsert;
  }

}
