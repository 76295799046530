import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Activity } from 'app/models/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient) { }

  public save(activity:Activity) {
    if(activity.id==0){
      const url = `${environment.APIEnpoint}/api/Activity`;
     return this.http.post(url, activity).pipe(map((res: any) => res.data));
    }else{
      console.log(activity);
      const url = `${environment.APIEnpoint}/api/Activity`;
      return this.http.put(url, activity).pipe(map((res: any) => res.data));
    }
  }

  public getActivityById(id:number): Observable<Activity[]>{
    const url = `${environment.APIEnpoint}/api/Activity/`+id+`/getbyid`;
    return this.http.get(url)
        .pipe(
          map((res: any) => {
            return res;
          })
        );
  }

  public getAllByIdStrategy(id:number): Observable<Activity[]>{
    const url = `${environment.APIEnpoint}/api/Activity/`+id+`/getallbystrategyid`;
    return this.http.get(url)
    .pipe(
      map((res: any) => {
        const activity: Activity[] = [];
        res.data.forEach(element => {
          activity.push(new Activity(
            element.id,
            element.description,
            element.strategyId,
            element.active));
        });
        return activity;
      })
    );
}

public delete(id){
  const url = `${environment.APIEnpoint}/api/Activity/`+id;
    return this.http.delete(url).pipe(map((res: any) => res.data));
}
}
