import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Rxjs
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Environment
import { environment } from 'environments/environment';
// Models
import { Perspective } from 'app/models/perspective.model';

@Injectable({
  providedIn: 'root'
})
export class PerspectiveService {

  constructor( private http: HttpClient ) { }

  getAll() {
    const url = `${environment.APIEnpoint}/api/Perspective`;

    return this.http.get( url ).pipe(
      map((res: any) => {
          const perspectives: Perspective[] = [];

          res.data.forEach((perspective: any) => {
            perspectives.push( Perspective.deserialize(perspective) );
          });
          return perspectives;
        }),
        catchError((err) => {
          return throwError(err);
        })
    );
  }

}
