// Models
import { Deparment } from './department.model';
import { MeasureUnit } from './measure-unit.model';
import { Objective } from './objetive.model';
import { Frecuency } from './frecuency.model';

export class Kpi {
    constructor (
        public id:                number,
        public name:              string,
        public keyName?:          string,
        public description?:      string,
        public minValue?:         number,
        public maxValue?:         number,
        public weight?:           number,
        public dataSource?:       string,
        public polarity?:         string,
        public order?:            number,
        public startDate?:        Date,
        public endDate?:          Date,
        public measureTypeId?:    number,
        public measureUnitId?:    number,
        public frecuencyId?:      number,
        public userId?:           number,
        public strategyId?:       number,
        public operativeGroupId?: number,
        public formula?:          string,
        public objectiveId?:      number,
        public isPlanned?:        boolean,
        public department?:       Deparment,
        public goalFinish?:       string,
        public valueLB?:          number,
        public yearLB?:           number,
        public periodLB?:         string,
        public valueMA?:          number,
        public yearMA?:           number,
        public periodMA?:         string,
        public variable1?:        string,
        public description1?:     string,
        public source1Id?:        number,
        public variable2?:        string,
        public description2?:     string,
        public source2Id?:        number,
        public reviewerId?:       number,
        public authorizerId?:     number,
        public color?:            string,
        public calculationId?: number,
        public decimalNumber?: number,
        public anotherSource?: string
     ) { }
}

export class KpiLight {

  static deserialize(o: any): KpiLight {
    return new KpiLight(
      o['kpiId'],
      o['name'],
      o['keyName'],
      new Date(o['startDate']),
      new Date(o['endDate']),
      o['measureUnit'] ? new MeasureUnit( o['measureUnit']['id'], o['measureUnit']['name']  ) : null,
      o['objetive']    ? new Objective(   o['objetive']['id'],    o['objetive']['name']     ) : null,
      o['frecuency']   ? new Frecuency(   o['frecuency']['id'],   o['frecuency']['name']    ) : null,
      o['department']  ? new Deparment(   o['department']['id'],  o['department']['name']   ) : null,
    );
  }

  constructor (
      public id:                number,
      public name:              string,
      public keyName?:          string,
      public startDate?:        Date,
      public endDate?:          Date,
      public measureUnit?:      MeasureUnit,
      public objective?:        Objective,
      public frecuency?:        Frecuency,
      public department?:       Deparment,
    ) { }
}
