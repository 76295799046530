// models
import { Kpi } from './kpi.model';
import { KpiTrack } from './kpi-track.model';
import { User } from './user.model';
import { DepartmentItem } from './department.model';
import { PerformanceAnalysis } from './performance-analysis.mode';

export class Datasheet extends Kpi{
      constructor(public id,
                  public name,
                  public keyName?,
                  public description?,
                  public formula?,
                  public measureUnitId?,
                  public polarity?,
                  public frecuencyId?,
                  public goalFinish?,
                  public valueLB?,
                  public yearLB?,
                  public periodLB?,
                  public valueMA?,
                  public yearMA?,
                  public periodMA?,
                  public variable1?,
                  public description1?,
                  public source1Id?,
                  public variable2?,
                  public description2?,
                  public source2Id?,
                  public nameu?:User[],
                  public position?:User[],
                  public eMail?: User[],
                  public extension?: User[],
                  public department?,
           
                  // public reviewer?:User[],
                  // public postionautho?: User[],
                  // public authorizerId?,
                  public objectiveId?,
                  public decimalNumber?,
                   public anotherSource?: string

                  
                  // public positiona?:User[]
               
      ){
        
        super(id,name);
    
      }      
}

export class DatasheetKpiTrack{
  constructor(
    public month?: KpiTrack,
    public numeratorPlanned?: KpiTrack,
    public denominatorPlanned?: KpiTrack,
    public planned?: KpiTrack,
    public numeratorReal?:KpiTrack,
    public performance?:KpiTrack ,
    public real?: KpiTrack,
    public denominatorReal?: KpiTrack,
  ){

  }
}

export class DatasheetAuthorization{
  constructor(
    public reviewer?: User[],
    public positionr?: User[],
    public authorizer?: User[],
    public positiona?: User[]
  ){

  }
}

export class DatasheetFeedback{
  constructor(
    public kpifeedback: PerformanceAnalysis
  ){}
}
