export class Strategy{

    constructor(
        public id: number,
        public name: string,
        public description: string,
        public resource: string,
        public startDate: Date,
        public endDate: Date,
        public objetiveId: number,
        public objetive: string,
        public perspective?: string,
        public responsableId?: number,
        public responsable?: string,
        public departmentId?:number,
        public department?: string,
        public reviewerId?:number,
        public authorizerId?:number,
        public active?:number
    ) { }
}
