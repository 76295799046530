import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { ROUTES } from '../../sidebar/sidebar-routes.config';

// RXJS
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { User } from 'app/models/user.model';
import { RegreshToken } from 'app/models/refreshToken.model';

import swal from 'sweetalert2';
import * as jwt_decode from 'jwt-decode';
import { RouteInfo } from '../../sidebar/sidebar.metadata';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  username: string;
  token: string;

  constructor(private http: HttpClient, private router: Router) {
    this.loadLocalStorage();
  }

  getAll(): Observable<User[]> {
    const url = `${environment.APIEnpoint}/api/User`;

    return this.http.get(url).pipe(
      map((res: any) => {
        const users: User[] = [];
        res.data.forEach(element => {
          const user = new User(
            element.id,
            element.userName,
            element.name,
            element.secondName,
            element.surName,
            element.secondSurName,
            element.telephone,
            element.cellPhone,
            element.extension,
            element.eMail
          );

          user.departmentId = element.departmentId;

          users.push(user);
        });
        return users;
      })
    );
  }

  login(username: string, password: string): Observable<boolean> {
    const url = `${environment.APIEnpoint}/auth/login`;

    return this.http
      .post(url, {
        username: username,
        password: password
      })
      .pipe(
        map((res: any) => {
          this.username = username;
          this.token = res.accessToken.token;

          const jwt = jwt_decode(this.token);
          const jsonJwt = JSON.parse(jwt.UserData);

          if (jsonJwt != null) {
            localStorage.setItem('token', this.token);
            localStorage.setItem('username', this.username);
            localStorage.setItem('refresh-token', res.refreshToken);
            localStorage.setItem(
              'dashboardId',
              jsonJwt.DashboardId ? jsonJwt.DashboardId : 0
            );
            localStorage.setItem('departmentId', jsonJwt.DepartmentId);
            localStorage.setItem('id', jsonJwt.Id);
            localStorage.setItem('name', jsonJwt.Name);
            localStorage.setItem('position', jsonJwt.Position);
            localStorage.setItem('secondName', jsonJwt.SecondName);
            localStorage.setItem('secondSurName', jsonJwt.SecondSurName);
            localStorage.setItem('surName', jsonJwt.SurName);
            localStorage.setItem('visionCia', jsonJwt.VisionCia);
            localStorage.setItem('calendarId', jsonJwt.CalendarId);
            localStorage.setItem('rolId', jsonJwt.Rol.Id);
          } else {
            return false;
          }

          return true;
        }),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  getPeriodsByUser(userId: number): Observable<number[]> {
    const url = `${environment.APIEnpoint}/api/Kpi/getperiods`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const periods: number[] = [];
        res.data.forEach(period => {
          periods.push(period);
        });
        return periods;
      })
    );
  }

  logOutConfirm(): void {
    swal({
      title: 'Cerrar Sesión',
      text: '¿Realmente deseas salir del sistema?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Salir ahora',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
        this.removeLocalStorage();
        this.router.navigateByUrl('/login');
      }
    });
  }

  logOut(): void {
    this.removeLocalStorage();
    this.router.navigateByUrl('/login');
  }

  refreshToken(): Observable<RegreshToken> {
    const url = `${environment.APIEnpoint}/auth/refreshToken`;

    const request = {
      accessToken: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refresh-token')
    };

    return this.http.post(url, request).pipe(
      map((res: any) => {
        const regreshToken = new RegreshToken(
          res.accessToken.token,
          res.refreshToken
        );
        this.setLocalStorage(
          regreshToken.accessToken,
          regreshToken.accessToken
        );
        return regreshToken;
      })
    );
  }

  isAuthenticated(): boolean {
    return this.token.length > 0 ? true : false;
  }

  loadLocalStorage(): void {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.username = localStorage.getItem('username');
    } else {
      this.token = '';
      this.username = null;
    }
  }

  // Obtener el menú del usuario
  public getMenu(): Observable<boolean> {
    const menu: RouteInfo[] = [];
    const url = `${environment.APIEnpoint}/api/User/getmenu`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const parentMenu = res.data.filter(r => r.systemObjectId === null && r.showOnMenu === true);

        parentMenu.forEach(parent => {
          const itemMenu: RouteInfo = {
            path:
              parent.actionName == null ? 'javascript:;' : parent.actionName,
            title: parent.name,
            icon: parent.menuIcon,
            class: parent.menuLabel,
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          };

          const secondLevel = res.data.filter(
            r => r.systemObjectId === parent.id && r.showOnMenu === true
          );

          secondLevel.forEach(child => {
            const secondChild: RouteInfo = {
              path:
                child.actionName == null ? 'javascript:;' : child.actionName,
              title: child.name,
              icon: '',
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            };
            const thirdLevel = res.data.filter(
              r => r.systemObjectId === child.id && r.showOnMenu === true
            );

            thirdLevel.forEach(third => {
              const thirdChild: RouteInfo = {
                path: third.actionName,
                title: third.name,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
              };
              secondChild.submenu.push(thirdChild);
            });

            if (secondChild.submenu.length > 0) {
              secondChild.class = 'has-sub';
            }
            itemMenu.submenu.push(secondChild);
          });
          menu.push(itemMenu);
        });
        let permissions = res.data.filter(p => p.actionName != null).map(p => p.actionName);
        permissions = this.addPermissions(permissions);

        localStorage.setItem('menu', JSON.stringify(menu));
        localStorage.setItem('permissions', JSON.stringify(permissions));

        return menu.length > 0 ? true : false;
      })
    );
  }

  public getResponsableByDeparment(id: number): Observable<User[]> {
    const url = `${environment.APIEnpoint}/api/User/${id}/getallbydepartmentid`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const users: User[] = [];
        res.data.forEach(element => {
          const user = new User(
            element.id,
            element.userName,
            element.name,
            element.secondName,
            element.surName,
            element.secondSurName,
            element.telephone,
            element.cellPhone,
            element.extension,
            element.eMail
          );

          user.departmentId = element.departmentId;

          users.push(user);
        });
        return users;
      })
    );
  }

  public setLocalStorage(token: string, refreshToken: string): void {
    this.token = token;
    localStorage.setItem('token', this.token);
    localStorage.setItem('refresh-token', refreshToken);
  }

  private removeLocalStorage(): void {
    this.username = null;
    this.token = '';

    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('refresh-token');
  }

  public getAccessToken(): string {
    return localStorage.getItem('token');
  }

  private addPermissions( permissions: string[] ) {
    permissions.push('admin/board/add');
    permissions.push('admin/board/edit');
    permissions.push('admin/strategymap/add');
    permissions.push('admin/strategymap/edit');
    permissions.push('admin/users/add');
    permissions.push('admin/users/edit');
    permissions.push('admin/objectives/add');
    permissions.push('admin/objectives/edit');
    permissions.push('admin/strategy/add');
    permissions.push('admin/strategy/edit');
    permissions.push('admin/strategy/detail');
    permissions.push('admin/stategy/inventary');
    permissions.push('admin/activity/add');
    permissions.push('admin/activity/edit');
    permissions.push('admin/company/edit');
    permissions.push('admin/kpis/add');
    permissions.push('admin/kpis/edit');
    permissions.push('admin/kpis/view');
    permissions.push('admin/department/add');
    permissions.push('admin/department/edit');
    permissions.push('admin/department/detail');
    permissions.push('admin/kpiTrack/add');
    permissions.push('admin/dataLoad/edit');
    permissions.push('dashboards/kpi-perspective');

    return permissions;
  }
}
