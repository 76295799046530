import { Strategy } from './strategy.model';
import { Objective } from './objetive.model';
import { Kpi } from './kpi.model';
import { User } from './user.model';
import { Deparment } from './department.model';
import { Activity } from './activity.model';

export class Initiativesheet{

    constructor(
        public name?: Strategy[],
        public description?: Strategy[],
        public objetiveName?: Objective[],
        public startDate?: Strategy[],
        public endDate?: Strategy[],
        public resource?: Strategy[],
        public nameu?:User[],
        public position?:User[],
        public eMail?: User[],
        public extension?: User[],
        public departmentName?: Deparment[],
        public reviewerId?: Strategy[],
        public authorizerId?: Strategy[],
        public positionr?:User[],
        public positiona?:User[],
        public estimatedDuration?: number
        

    ){}
}

export class InitiativesheetActivity{
    constructor(
        public description?: Activity
    ){}
}

export class InitiativesheetKpi{
    constructor(
        public name?: Kpi
    ){}
}