export class Perspective {
    constructor(
        public id:                  number,
        public name:                string,
        public active?:             boolean,
        public advance?:            number
    ) { }

// tslint:disable-next-line: member-ordering
    static deserialize(o: any): Perspective {
        return new Perspective(
            o['id'],
            o['name'],
            o['active'],
            o['advance']
        );
    }
}
