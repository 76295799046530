import { ProposalType } from 'app/models/proposal-type.model';

export class ProposalValue {

    static deserialize(o: any): ProposalValue {
        return new ProposalValue(
            o['id'],
            o['proposalTypeId'],
            o['strategyMapId'],
            o['dataValue'],
            o['proposalType']
        );
    }

    constructor(
        public id: number,
        public proposalTypeId: number,
        public strategyMapId: number,
        public dataValue: string,
        public proposalType?: ProposalType
    ) {}
}
