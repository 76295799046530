import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { EmptyListMessageComponent } from './empty-list-message/empty-list-message.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { MulticolorProgressBarComponent } from './multicolor-progress-bar/multicolor-progress-bar.component';
import { CardKpiComponent } from './card-kpi/card-kpi.component';

import { NgbModule, NgbTooltipModule  } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbTooltipModule 
    ],
    declarations: [
        EmptyListMessageComponent,
        LoadingScreenComponent,
        MulticolorProgressBarComponent,
        CardKpiComponent
    ],
    exports: [
        EmptyListMessageComponent,
        LoadingScreenComponent,
        MulticolorProgressBarComponent,
        CardKpiComponent
    ]
})
export class CoreModule { }
