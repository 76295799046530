import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-list-message',
  templateUrl: './empty-list-message.component.html',
  styleUrls: ['./empty-list-message.component.scss']
})
export class EmptyListMessageComponent implements OnInit {

  @Input('title') title;
  @Input('message') message;
  @Input('icon') icon;
 
  constructor() { }

  ngOnInit() {
  }

}
