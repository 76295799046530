import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService,
         DashboardService,
         AuthenticationTokenService,
         ObjetiveService, // Todo: Usar el servicio compartido
         ObjectiveService,
         StrategyMapService,
         KpiService,
         PerspectiveService,
        StorageService,
        PerformanceAnalysisService,
        StrategyService,
        FrecuencyService,
        MeasureTypeService,
        MeasureUnitService,
        KpiTrackService, DatasheetService, InitiativesheetService, CalnedarService} from './service.index';

import { LoadingScreenService } from './loading-screen.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// INterceptors
import { LoadingScreenInterceptor } from '../interceptors/loading.interceptor';
import { DepartmentService } from './board/department.service';
import { ScopeService } from './Administrator/scope.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    UserService,
    DashboardService,
    LoadingScreenService,
    KpiService,
    ObjectiveService,
    StrategyMapService,
    PerspectiveService,
    StorageService,
    DepartmentService,
    PerformanceAnalysisService,
    StrategyService,
    ScopeService,
    FrecuencyService,
    MeasureTypeService,
    MeasureUnitService,
    KpiTrackService,
    DatasheetService,
    InitiativesheetService,
    CalnedarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ]
})
export class ServiceModule { }
