export class Dataload {
    constructor(
       public idprocessbinnacle?: number,
       public procesogeneral?: string,
       public proceso?: string,
       public fechainicio?: Date,
       public fechatermino?: Date,
       public estatus?: string,
       public errordescription?: string

    ){}
}