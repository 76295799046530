import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

// RXJS
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

//Models
import { Company } from 'app/models/company.model';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  public getAll() {
    const url = `${environment.APIEnpoint}/api/Company`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const companies: Company[] = [];

        res.data.forEach(c => {
          companies.push(
            new Company(
              c.id,
              c.name,
              c.mission,
              c.ciaValues,
              c.vision,
              c.logo,
              c.active
            )
          );
        });
        return companies;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getById(id: number) {
    const url = `${environment.APIEnpoint}/api/Company/${id}/getbyid`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return new Company(
          res.data.id,
          res.data.name,
          res.data.mission,
          res.data.ciaValues,
          res.data.vision,
          res.data.logo,
          res.data.active
        );
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public save(company: Company) {
    const url = `${environment.APIEnpoint}/api/Company`;
    const dataRequest = this.getRequestData(company);

    if (dataRequest.id != 0) {
      return this.http.put(url, dataRequest).pipe(map((res: any) => res.data));
    } else {
      console.log('err');
    }
  }

  private getRequestData(company: Company): any {
    return {
      name: company.name,
      mission: company.mission,
      ciaValues: company.ciaValues,
      vision: company.vision,
      logo: company.logo,
      id: company.id,
      active: company.active
    };
  }
}
