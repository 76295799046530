import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Datasheet,DatasheetKpiTrack, 
         DatasheetAuthorization, 
         DatasheetFeedback} from '../../../models/datasheet.model';
import { Kpi } from '../../../models/kpi.model';
import { User } from '../../../models/user.model';
import { Deparment } from '../../../models/department.model';
import { KpiTrack } from '../../../models/kpi-track.model';

@Injectable({
  providedIn: 'root'
})
export class DatasheetService {

  constructor(private http: HttpClient) { }

public getAllByKpi(id: number): Observable<any>{
  const url = `${environment.APIEnpoint}/api/Reports/${id}/getdatasheet`;
return this.http.get(url).pipe(
  map((res:any)=>{        
  return new Datasheet(
    // res.data.kpiItem.objetiveName,
   0,
    res.data.kpiItem.name,
    '',
    res.data.kpiItem.description,
    res.data.kpiItem.formula,
    res.data.kpiItem.measureUnitName,
    res.data.kpiItem.polarity,
    res.data.kpiItem.frecuencyName,
    res.data.kpiItem.goalFinish,
    res.data.kpiItem.valueLB,
    res.data.kpiItem.yearLB,
    res.data.kpiItem.periodLB,
    res.data.kpiItem.valueMA,
    res.data.kpiItem.yearMA,
    res.data.kpiItem.periodMA,
    res.data.kpiItem.variable1,
    res.data.kpiItem.description1,
    res.data.kpiItem.sourceName1,
    res.data.kpiItem.variable2,
    res.data.kpiItem.description2,
    res.data.kpiItem.sourceName2,
    res.data.kpiItem.responsable.name,
    res.data.kpiItem.responsable.position,
    res.data.kpiItem.responsable.eMail,
    res.data.kpiItem.responsable.extension,
    res.data.kpiItem.responsable.departmentName,
    res.data.kpiItem.objetiveName,
    res.data.kpiItem.decimalNumber,
    res.data.kpiItem.anotherSource
  
  


   )
  }),
  catchError((err)=>{
    return throwError(err);
  })
  
);

}


public  getAllByKpiTrack( id: number ): Observable<any> {
    const url = `${environment.APIEnpoint}/api/Reports/${id}/getdatasheet`;
    return this.http.get(url).pipe(map((res:any)=>{
      const kpitracks: DatasheetKpiTrack[]=[];

      res.data.kpitrack.forEach(element => {
        kpitracks.push(
          new DatasheetKpiTrack(
            element.month,
            element.numeratorPlanned,
            element.denominatorPlanned,
            element.planned,
            element.numeratorReal,
            element.performance,
            element.real,
            element.denominatorReal
          ));
      });
      return kpitracks;
    }),
    catchError((err)=>{
      return throwError(err);
    })
    
    );    
  }


  public getFeedback(id: number): Observable<DatasheetFeedback[]> {
    const url = `${environment.APIEnpoint}/api/Reports/${id}/getdatasheet`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const kpifeedback = res.data.kpifeedback

        if (kpifeedback) {
          kpifeedback.forEach(d => {
            d.kpifeedback
          });
          // console.log(kpifeedback);
        }
        return kpifeedback;
       

      }),
      catchError((err) => {
        return throwError(err);
      })
    )

  }

  public getAllByAuthorization(id: number): Observable<any>{
    const url = `${environment.APIEnpoint}/api/Reports/${id}/getdatasheet`;
  return this.http.get(url).pipe(
    map((res:any)=>{        
    return new DatasheetAuthorization(
      (res.data.kpiItem.reviewer != null) ? res.data.kpiItem.reviewer.name:null,
      (res.data.kpiItem.reviewer != null) ? res.data.kpiItem.reviewer.position:null,
      (res.data.kpiItem.authorizer !=null)? res.data.kpiItem.authorizer.name:null,
      (res.data.kpiItem.authorizer !=null)? res.data.kpiItem.authorizer.position:null
     )
    }),
    catchError((err)=>{
      return throwError(err);
    })
    
  );
  
  }
}

