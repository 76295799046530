import { Routes, RouterModule } from '@angular/router';


export const ADMIN_ROUTES: Routes = [
    {
        path: 'admin',
        loadChildren:  './pages/administrator/administrator.module#AdministratorPagesModule'
      }
];

