import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Envarroment
import { environment } from 'environments/environment';

// Models
import { Calendar } from 'app/models/calendar.model';
import { KpiTrack } from 'app/models/kpi-track.model';

@Injectable({
  providedIn: 'root'
})
export class KpiTrackService {
  constructor(private http: HttpClient) {}

  // Obtener el listado del calendar del dashboard activo
  getCalendarsFromDashBoardAlive(): Observable<Calendar[]> {
    const url = `${environment.APIEnpoint}/api/Calendar/GetCalendarFromDashboardAlive`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const data: Calendar[] = [];
        if (res.data) {
          res.data.forEach(d => {
            const calendar = new Calendar(
              d.id,
              d.month,
              d.year,
              d.month_Text,
              d.year_Text,
              d.relativeId,
              d.month_Year_Text
            );
            data.push(calendar);
          });
        }
        return data;
      })
    );
  }

  // Obtener el listado del calendar del dashboard activo
  getCalendarsForTrack(id: number): Observable<Calendar[]> {
    const url = `${environment.APIEnpoint}/api/Calendar/${id}/getcalendarfortrack`;
    return this.http.get(url).pipe(
      map((res: any) => {
        const data: Calendar[] = [];
        if (res.data) {
          res.data.forEach(d => {
            const calendar = new Calendar(
              d.id,
              d.month,
              d.year,
              d.month_Text,
              d.year_Text,
              d.relativeId,
              d.month_Year_Text
            );
            data.push(calendar);
          });
        }
        return data;
      })
    );
  }

  // Obtiene el seguimiento de un indicador donde el performance sea mayor a cero
  getTrackByKpiWithPerformance(
    kpiId: number,
    dashboardId: number
  ): Observable<KpiTrack[]> {
    const url = `${environment.APIEnpoint}/api/KpiTrack/getallbykpiiddashboardid`;
    const params = {
      dashboardId: dashboardId,
      kpiId: kpiId
    };

    return this.http.post(url, params).pipe(
      map((res: any) => {
        const data: KpiTrack[] = [];

        if (res.data) {
          res.data.forEach(d => {
            const track = new KpiTrack(
              d.id,
              d.planned,
              d.real,
              d.calendarId,
              d.dashboardId,
              d.performance,
              ''
            );
            track.pointsEarned = d.pointsEarned;
            track.denominatorPlanned = d.denominatorPlanned;
            track.denominatorReal = d.denominatorReal;
            track.numeratorPlanned = d.numeratorPlanned;
            track.numeratorReal = d.numeratorReal;
            track.color = d.color;

            data.push(track);
          });
        }
        return data;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // Obtiene el seguimiento de un indicador
  getTrackByKpi(kpiId: number, dashboardId: number): Observable<KpiTrack[]> {
    const url = `${environment.APIEnpoint}/api/KpiTrack/getallbykpiiddashboardid`;
    const params = {
      dashboardId: dashboardId,
      kpiId: kpiId
    };

    return this.http.post(url, params).pipe(
      map((res: any) => {
        const data: KpiTrack[] = [];

        if (res.data) {
          res.data.forEach(d => {
            const track = new KpiTrack(
              d.id,
              d.planned,
              d.real,
              d.calendarId,
              d.dashboardId,
              d.performance,
              ''
            );
            track.numeratorPlanned = d.numeratorPlanned;
            track.denominatorPlanned = d.denominatorPlanned;
            track.numeratorReal = d.numeratorReal;
            track.denominatorReal = d.denominatorReal;
            data.push(track);
          });
        }
        return data;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // eliminar un kpiTrack existente
  delete(id: number) {
    const url = `${environment.APIEnpoint}/api/KpiTrack/${id}`;
    return this.http.delete(url);
  }

  // Guardar o actualizar los datos de un kpitrack
  save(kpiTrack: any) {
    const url = `${environment.APIEnpoint}/api/KpiTrack`;

    if (kpiTrack.id === 0) {
      return this.http.post(url, kpiTrack).pipe(map((res: any) => res));
    } else {
      return this.http.put(url, kpiTrack).pipe(map((res: any) => res));
    }
  }
}
