export class DashBoard {
    constructor(
        public id: number,
        public name: string,
        public description: string,
        public isPublic?: boolean,
        public start?: Date,
        public end?: Date,
        public userId?: number,
        public companyId?: number,
        public active?: boolean
    ) { }
}
