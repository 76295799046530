import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

// Components
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { DenyAccessComponent } from './pages/deny-access/deny-access.component';

// Routes
import { ADMIN_ROUTES } from './shared/routes/administrator.routes';
import { DASHBOARD_ROUTES } from './shared/routes/dashboard.routes';
import { REPORT_ROUTES } from './shared/routes/report.routes';
import { STRATEGYMAP_ROUTES } from './shared/routes/strategy-map.routes';
import { ORGCHART_ROUTES } from './shared/routes/organization.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'deny-access', component: DenyAccessComponent },
      { path: 'page-not-found', component: PageNotFoundComponent }
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Vistas de administración' },
    children: ADMIN_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Tablero perspectiva' },
    children: DASHBOARD_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Reportes' },
    children: REPORT_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Mapa Estratégico' },
    children: STRATEGYMAP_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Organigrama' },
    children: ORGCHART_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
