import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Strategy } from 'app/models/strategy.model';

@Injectable({
  providedIn: 'root'
})
export class StrategyService {

  constructor( private http: HttpClient ) { }

  getAll(): Observable<Strategy[]> {
    const url = `${environment.APIEnpoint}/api/Strategy`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const strategies: Strategy[] = [];

        res.data.forEach( element => {
          strategies.push( new Strategy(
            element.id,
            element.name,
            element.description,
            element.resource,
            new Date(element.startDate),
            new Date(element.endDate),
            element.objetiveId,
            element.objetive.name,
            element.objetive.perspective.name,
            element.responsableId,
            (element.responsable==null)?"":element.responsable.fullName,
            0,
            null,
            0,
            element.year )
          );
        });

        return strategies;

      }), catchError((err) => {

        return throwError(err);

      })
    );
  }

  getByPeriod(period:number): Observable<Strategy[]> {
    const url = `${environment.APIEnpoint}/api/Strategy/`+period+`/getbyobjetiveperiod`;
    return this.http.get( url ).pipe(
      map( (res: any) => {
        const strategies: Strategy[] = [];
        console.log(res);
        res.data.forEach( element => {
          strategies.push( new Strategy(
            element.id,
            element.name,
            element.description,
            element.resource,
            new Date(element.startDate),
            new Date(element.endDate),
            element.objetiveId,
            element.objetive.name,
            element.objetive.perspective.name,
            element.responsableId,
            (element.responsable==null)?"":element.responsable.fullName,
            0,
            null,
            0,
            element.year )
          );
        });

        return strategies;

      }), catchError((err) => {

        return throwError(err);

      })
    );
  }

  getByDepartment(departmentId:number): Observable<Strategy[]>{
    const url = `${environment.APIEnpoint}/api/Strategy/${departmentId}/getbydepartmentid`;
    return this.http.get( url).pipe(map(( res:any) =>{
      const strategies: Strategy[]= [];

      res.data.forEach(element =>{
        strategies.push(new Strategy(

          element.id,
          element.name,
          element.description,
          element.resource,
          new Date(element.startDate),
          new Date(element.endDate),
          element.objetiveId,
          element.responsableId,element.responsable.fullName, element.departmentId)
          
          );
      });
      return strategies;
    }), catchError((err)=>{
      return throwError(err);
    })
    );
  }

 // NO TOCAR ESTE METODO PUEDE AFECTAR A LA FICHA INICIATIVA
  getByDepartmentdos(departmentId:number): Observable<Strategy[]>{
    const url = `${environment.APIEnpoint}/api/Strategy/${departmentId}/getbydepartmentid`;
    return this.http.get( url).pipe(map(( res:any) =>{
      const strategies: Strategy[]= [];

      res.data.forEach(element =>{
        strategies.push(new Strategy(

          element.id,
          element.name,
          element.description,
          element.resource,
          new Date(element.startDate),
          new Date(element.endDate),
          element.objetiveId,
          element.responsableId, element.departmentId)
          
          );
      });
      return strategies;
    }), catchError((err)=>{
      return throwError(err);
    })
    );
  }


  getPeriods(): Observable<[]> {
    const url = `${environment.APIEnpoint}/api/Strategy/getperiods`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const periods: [] = res.data;
        return periods;

      }), catchError((err) => {

        return throwError(err);

      })
    );
  }

  public requestInventary(anio:number,department:number) {
    if(anio==0){
      anio=null;
    }
    if(department==0){
      department =null;
    }
    console.log(anio,department);
      const url = `${environment.APIEnpoint}/api/Strategy/getinventory`;
     return this.http.post(url, {
      year:anio,
      departmentId:department
     }).pipe(map((res: any) => res.data));
    
  }
  

  public save(strategy:Strategy) {
    console.log(strategy);
    if(strategy.id==0){
      const url = `${environment.APIEnpoint}/api/Strategy`;
     return this.http.post(url, strategy).pipe(map((res: any) => res.data));
    }else{
      const url = `${environment.APIEnpoint}/api/Strategy`;
      return this.http.put(url, strategy).pipe(map((res: any) => res.data));
    }
  }
  
  public delete(id){
    const url = `${environment.APIEnpoint}/api/Strategy/`+id;
      return this.http.delete(url).pipe(map((res: any) => res.data));
  }

  public getStrategyById(id): Observable<Strategy[]>{
    const url = `${environment.APIEnpoint}/api/Strategy/`+id+`/getbyid`;
    return this.http.get(url)
        .pipe(
          map((res: any) => {
            return res;
          })
        );
  }
}
