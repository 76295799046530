import { Progressbar } from './progress-bar.model';

export class CardKpiItem {
    constructor(
        public description: string,
        public progressBar: Progressbar,
        public goal: number,
        public advance: number,
        public performance: number,
        public key?: string,
        public id?: number,
        public measureUnitId?: number,
        public objetiveName?: string,
        public color?: string,
        public objectiveId?: number,
        public objectiveKey?: string,
        public FrecuencyName?: string
    ) { }

    get getUnit() : string {
        switch( this.measureUnitId ) {
            case 21:
                return '%';
            default:
                return '';
        }
    }
}
