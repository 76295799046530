import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Rxjs
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Environment
import { environment } from 'environments/environment';
// Models
import { StrategyMap } from 'app/models/strategy-map.model';
import { DateUtilities } from 'app/shared/utilities/date.utilitie';

@Injectable({
  providedIn: 'root'
})
export class StrategyMapService {

  constructor( private http: HttpClient ) {}

  getAll() {
    const url = `${environment.APIEnpoint}/api/StrategyMap`;

    return this.http.get( url ).pipe(
      map((res: any) => {
          const maps: StrategyMap[] = [];

          res.data.forEach((strategyMap: any) => {
            maps.push( StrategyMap.deserialize(strategyMap) );
          });
          return maps;
        }),
        catchError((err) => {
          return throwError(err);
        })
    );
  }

  getByDashboardAlive() {
    const url = `${environment.APIEnpoint}/api/StrategyMap/getbydashboardalive`;

    return this.http.get( url ).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getById( id: number) {
    const url = `${environment.APIEnpoint}/api/StrategyMap/${id}/getbyid`;

    return this.http.get( url ).pipe(
      map((res: any) => {
          return StrategyMap.deserialize(res.data);
        }),
        catchError((err) => {
          return throwError(err);
        })
    );
  }

  deleteObjective(body: any) {
    const url = `${environment.APIEnpoint}/api/StrategyMapObjetive`;

    const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: body
    };

    return this.http.delete(url, httpOptions).pipe( map( (res: any) => res.success ), catchError( (err) => { return throwError(err); } ) );
  }

  addObjective(body: any) {
    const url = `${environment.APIEnpoint}/api/StrategyMapObjetive`;

    return this.http.post(url, body).pipe(
      map( (res: any) => res.data ),
      catchError( (err) => { return throwError(err); } )
    );
  }

  save(strategyMap: StrategyMap) {
    const url = `${environment.APIEnpoint}/api/StrategyMap`;
    const dataRequest = this.getRequestData( strategyMap );

    if ( dataRequest.id === 0 ) {
      return this.http.post(url, dataRequest).pipe( map( (res: any) => res.data ) );
    } else {
      return this.http.put(url, dataRequest).pipe( map( (res: any) => res.data ) );
    }
  }

  delete(id: number) {
    console.log(id);
    const url = `${environment.APIEnpoint}/api/StrategyMap/`+id;
      console.log(url);
      return this.http.delete(url).pipe( map( (res: any) => res.data ) );
    
  }

  private getRequestData( strategyMap: StrategyMap ): any {

    return {
      name: strategyMap.name,
      startDate: DateUtilities.dateSerialize(strategyMap.startDate),
      endDate: DateUtilities.dateSerialize(strategyMap.endDate),
      percentFulfilled: strategyMap.percentFulfilled,
      dashboardId: strategyMap.dashboardId,
      id: strategyMap.id,
      vision: strategyMap.vision,
      mision: strategyMap.mision,
      values: strategyMap.values
    };
  }

}
