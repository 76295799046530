
export class User{

    constructor(
        public id: number,
        public userName: string,
        public name: string,
        public secondName: string,
        public surName?: string,
        public surSecondName?: string,
        public telePhone?: string,
        public cellPhone?: string, 
        public extension?: string,
        public email?: string,
        public position?: string,
        public departmentId?:number,
        public active?: boolean,
        public password?: string,
        public department?: string,
    ) { }
}
