import { User } from 'app/models/user.model';

export class Deparment {
  constructor(
    public id: number,
    public name: string,
    public departmentId?: number,
    public companyId?: number,
    public userId?: number,
    public active?: boolean,
    public user?: any
  ) {}
}

export class DepartmentItem {
  constructor(
    public name: string,
    public fullName: string,
    public id: number
  ) {}
}
