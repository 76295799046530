export class PerformanceAnalysis{
    constructor(
        public id:number,
        public feedBack: string,
        public userId: number,
        public dashboardId?: number,
        public kpiTrackId?: number,
        public active?: boolean,
        public registerDate?: Date,
        public showMore?: boolean
    ) { }
}

