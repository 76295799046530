export class DateUtilities {

    public static dateSerialize( date: Date): string {

        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        const year = String(date.getFullYear());
  
        return `${year}/${month}/${day}`;
      }
}
