import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Objective } from '../../../models/objetive.model';
import { Kpi } from 'app/models/kpi.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveService {

  constructor( private http: HttpClient ) { }

  // Obtener todos los objetivos por año
  getByYear( year: string ) {
    const url = `${environment.APIEnpoint}/api/Objetive/${year}/getallbyyear`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const objectives: Objective[] = [];

        res.data.forEach(element => {
          objectives.push( new Objective(
            element.id,
            element.name,
            element.scopeId,
            new Date(element.startDate),
            null,
            element.perspectiveId,
            null,
            null,
            element.activ
            )
          );
        });

        return objectives;
      }), catchError((err) => {
        return throwError(err);
      })
    );
  }

  getImpactedByStrategyMapId( id: number ) {
    const url = `${environment.APIEnpoint}/api/StrategyMapObjetive/${id}/getallbystrategymapid`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const objectives: any[] = res.data.map( o => { return { objetiveId: o.objetiveId, objetiveAfectedId: [] }; });
        res.data.forEach(o => {
          objectives.find(obj => o.objetiveId === obj.objetiveId).objetiveAfectedId.push(o.objetiveAfectedId);
        });

        return objectives;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // Obtener el listado de objetivos
  getAll( ) {
    const url = `${environment.APIEnpoint}/api/Objetive`;
    return this.http.get( url ).pipe(map( (res: any) => {
                                                    const objectives: Objective[] = [];
                                                    res.data.forEach(element => {
                                                      objectives.push( new Objective( 
                                                        element.id,
                                                        element.name,
                                                        element.scopeId,
                                                        new Date(element.startDate),
                                                        null,
                                                        element.perspectiveId,
                                                        element.perspective.name,
                                                        null,
                                                        element.active,
                                                        null,
                                                        null,
                                                        null,
                                                        element.period,
                                                        element.keyName
                                                      ));
                                                    });
                                                    return objectives;
                                                 }
                                      ), catchError((err) => {
                                          return throwError(err);
                                      }));
  }

  // Obtener los objetivos del mapa
  getByDashboardId(id: number) {
    const url = `${environment.APIEnpoint}/api/Objetive/${id}/getobjetivesbydashboardid`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const objectives: Objective[] = [];

        res.data.forEach(element => {
          objectives.push( new Objective(
            element.id,
            element.name,
            element.scopeId,
            new Date(element.startDate),
            new Date(element.endDate),
            element.perspectiveId,
            null,
            null,
            element.activ,
            null,
            null,
            null,
            element.period,
            element.keyName
            )
          );
        });

        return objectives;
      }), catchError((err) => {
        return throwError(err);
      })
    );
  }

  getRelatedByStrategyMapId(id: number) {
    const url = `${environment.APIEnpoint}/api/StrategyMapObjetive/${id}/getallbystrategymapid`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const related: any[] = [];

        res.data.forEach(element => {
          related.push( {
            oId: element.objetiveId,
            aId: element.objetiveAfectedId
          });
        });

        return related;
      }), catchError((err) => {
        return throwError(err);
      })
    );
  }
}
