import { RangeProgressBar } from './range-progress-bar.model';

export class Progressbar {
    constructor(
        public description: string,
        public value: string ,
        public ranges: RangeProgressBar[],
        public color?: string,
        public maxValue?: number,
        public minValue?: number,
    ) {}

    get fixedValue(): string {
        const valueNumber = Number(this.value);
        if( valueNumber < 10 ) {
            return '10%';
        }

        if( valueNumber > 90) {
            return '90%';
        }

        return `${this.value}%`;
    }
   
    get valueNumber() : number {
        return Number(this.value);
    }
}
