export class Calendar {

    constructor(
        public id: number,
        public month: number,
        public year: number,
        public monthText: string,
        public yearText: string,
        public relativeId: number,
        public monthYearText: string
    ) { }
}
