import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// MODELS
import { CardKpi } from 'app/models/components/card-kpi.model';
import { CardKpiItem } from 'app/models/components/card-kpi-item.model';

@Component({
  selector: 'app-card-kpi',
  templateUrl: './card-kpi.component.html',
  styleUrls: ['./card-kpi.component.scss']
})
export class CardKpiComponent implements OnInit {

  _cardKpi: CardKpi;

  @Output() onClickKpiEvent = new EventEmitter<CardKpiItem>();
  @Output() onClickCardTitle =  new EventEmitter<any>();
  @Input() set cardKpi(value: CardKpi) {
    this._cardKpi = value;
  }

  get cardKpi() : CardKpi{
    return  this._cardKpi;
  }

  constructor( private _router: Router ) { }

  ngOnInit() {
  }

  onClickKpi( kpi: CardKpiItem ) {
    this.onClickKpiEvent.emit(kpi);
  }

  onClickCard () {
    this.onClickCardTitle.emit( this.groupByObjectiveName() );
    this.groupByObjectiveName();
  }

  getColorClass( hexaColor: string ) {
  
    let cssClasses;
     switch(hexaColor) {
        case '#00ba18':
          cssClasses = { 'circle-gree': true };
          break;
        case '#ffef47':
          cssClasses = { 'circle-yellow': true };
          break;
        case '#ff0000':
          cssClasses = { 'circle-red': true };
          break;
        default:
          cssClasses = { 'circle-red': false };
          break;
     }

     return cssClasses;
  }

  private groupByObjectiveName(): any {

     const objectives: any[] = this._cardKpi.items.map( x => x.objetiveName).filter((v, i, a) => a.indexOf(v) === i);
     const groupObjectives: any[] = [];

     objectives.forEach( o => {
        groupObjectives.push({
           'objective': o,
           'key' : this._cardKpi.items.filter( x => x.objetiveName === o)[0].objectiveKey,
           'kpis': this._cardKpi.items.filter( x => x.objetiveName === o)
        })
     });

     const data = {
      'objectives': groupObjectives,
      'perspective': this._cardKpi.title,
      'advance': this._cardKpi.advance
     };

     return data;

  }

}
