import { Calendar } from './calendar.model';

export class KpiTrack {
    constructor(
        public id: number,
        public planned: number,
        public real: number,
        public calendarId: number,
        public dashboardId: number,
        public performance: number,
        public description?: string,
        public calendar?: Calendar,
        public numeratorPlanned?: number,
        public denominatorPlanned?: number,
        public numeratorReal?: number,
        public denominatorReal?: number,
        public pointsEarned?: number,
        public color?: string
    ) { }
}