import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { throwError, Observable, empty, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Calendar } from 'app/models/calendar.model';

@Injectable({
  providedIn: 'root'
})
export class CalnedarService {

  constructor( private  http: HttpClient ) { }

  getAll(): Observable<Calendar[]>{

    const url = `${environment.APIEnpoint}/api/Calendar`;
		return this.http.get(url).pipe( map( (res:any) => {
      const data: Calendar[] = [];

      if ( res.data ) {
        res.data.forEach( d => {
          const calendar = new Calendar(d.id, d.month, d.year, d.month_Text, d.year_Text, d.relativeId, d.month_Year_Text);
          data.push( calendar );
        });
      }

      return data;
    }));

  }
}
