import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Rxjs
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Environment
import { environment } from 'environments/environment';
// Models
import { ProposalType } from 'app/models/proposal-type.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalTypeService {

  constructor( private http: HttpClient ) { }

  getAll() {
    const url = `${environment.APIEnpoint}/api/ProposalType`;

    return this.http.get( url ).pipe(
      map((res: any) => {
        const proposalTypes: ProposalType[] = [];

        res.data.forEach( (pt: any) => {
          proposalTypes.push(ProposalType.deserialize(pt));
        });

        return proposalTypes;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
