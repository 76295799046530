import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Frecuency } from 'app/models/frecuency.model';

@Injectable({
  providedIn: 'root'
})
export class FrecuencyService {

  constructor( private http: HttpClient ) { }

  getAll(): Observable<Frecuency[]> {
    const url = `${environment.APIEnpoint}/api/Frecuency`;

    return this.http.get( url ).pipe(
      map( (res: any) => {
        const fecuencies: Frecuency[] = [];

        res.data.forEach( element => {
          fecuencies.push( new Frecuency( element.id, element.name ));
        });

        return fecuencies;

      }), catchError((err) => {
        return throwError(err);
      })
    );
  }
}
