import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Models
import { Objective } from '../../../models/objetive.model';

@Injectable({
  providedIn: 'root'
})
export class ObjetiveService {

  constructor( private http: HttpClient ) { }

  getByYear( year: string ) {
    const url = `${environment.APIEnpoint}/api/Objetive/${year}/getallbyyear`;
    return this.http.get( url ).pipe(map( (res: any) => {
                                                    const objectives: Objective[] = [];
                                                    res.data.forEach(element => {
                                                      objectives.push( new Objective( 
                                                        element.id,
                                                        element.name,
                                                        element.scopeId,
                                                        new Date(element.startDate),
                                                        null,
                                                        element.perspectiveId,
                                                        null,
                                                        null,
                                                        element.activ
                                                      ));
                                                    });
                                                    return objectives;
                                                 }
                                      ), catchError((err) => {
                                          return throwError(err);
                                      }));
  }

  getAll( ) {
    const url = `${environment.APIEnpoint}/api/Objetive`;
    return this.http.get( url ).pipe(map( (res: any) => {
                                                    const objectives: Objective[] = [];
                                                    res.data.forEach(element => {
                                                      objectives.push( new Objective( 
                                                        element.id,
                                                        element.name,
                                                        element.scopeId,
                                                        new Date(element.startDate),
                                                        null,
                                                        element.perspectiveId,
                                                        null,
                                                        null,
                                                        element.active,
                                                        null,
                                                        null,
                                                        null,
                                                  
                                                        element.period,
                                                        element.keyName
                                                      
                                                      ));
                                                    });
                                                    return objectives;
                                                 }
                                      ), catchError((err) => {
                                          return throwError(err);
                                      }));
  }

  getById(id) {
    const url = `${environment.APIEnpoint}/api/Objetive/`+id+`/getbyid`;
    return this.http.get( url ).pipe(map( (res: any) => {
                                                    return res;
                                                 }
                                      ), catchError((err) => {
                                          return throwError(err);
                                      }));
  }

  getByPeriodPerspective(period:number,persp:number ) {
    if(period==0){
      period=null
    }
    if(persp==0){
      persp=null
    }
    const url = `${environment.APIEnpoint}/api/Objetive/getallbyperspectiveorperiod`;
    return this.http.post( url,{
      perspectiveId: persp,
      period:period
    } ).pipe(map( (res: any) => {
                                                    const objectives: Objective[] = [];
                                                    res.data.forEach(element => {
                                                      objectives.push( new Objective( 
                                                        element.id,
                                                        element.name,
                                                        element.scopeId,
                                                        new Date(element.startDate),
                                                        null,
                                                        element.perspectiveId,
                                                        element.perspective.name,
                                                        null,
                                                        element.active,
                                                        null,
                                                        null,
                                                        null,
                                                  
                                                        element.period,
                                                        element.keyName
                                                      
                                                      ));
                                                    });
                                                    return objectives;
                                                 }
                                      ), catchError((err) => {
                                          
                                          return throwError(err);
                                      }));
  }

  public save(objetive: Objective) {
    console.log(objetive);

    // const url = `${environment.APIEnpoint}/api/Objetive`;
    // const dataRequest = this.getRequestData(objetive);

    // if (dataRequest.id === 0) {
    //   return this.http.post(url, dataRequest).pipe(map((res: any) => res.data));
    // } else {
    //   return this.http.put(url, dataRequest).pipe(map((res: any) => res.data));
    // }



    if(objetive.id==0){
    const url = `${environment.APIEnpoint}/api/Objetive`;
      return this.http.post(url, objetive).pipe(map((res: any) => res.data));
    }else{
      const url = `${environment.APIEnpoint}/api/Objetive`;
      return this.http.put(url, objetive).pipe(map((res: any) => res.data));
    }
  }
  
  public delete(id){
    const url = `${environment.APIEnpoint}/api/Objetive/`+id;
      return this.http.delete(url).pipe(map((res: any) => res.data));
  }

  public ObjectiveByPeriod(anio:number){
    console.log(anio);
    const url = `${environment.APIEnpoint}/api/Objetive/getallbyperiodofobjetive`;
   return this.http.post(url, {
    year:anio
   }).pipe(map((res: any) =>{ const objectives: Objective[] = [];
    res.data.forEach(element => {
      objectives.push( new Objective( 
        element.id,
        element.name,
        element.scopeId,
        new Date(element.startDate),
        null,
        element.perspectiveId,
        null,
        null,
        element.active,
        null,
        null,
        null,
        element.period,
        element.keyName
      
      ));
    });
    return objectives;
  }));
  
}


}
