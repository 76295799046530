export class KpiRange {

    constructor( 
        public id: number,
        public startRange: number,
        public endRange: number,
        public colorName: string,
        public kpiId: number,
        public hexaCodeColor?: string,
        public startLogic?: string,
        public endLogic?: string
    ) { }

}


