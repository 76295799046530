import { ProposalValue } from './proposal-value.model';
import { Objective } from 'app/models/objetive.model';

export class StrategyMap {

    static deserialize(o: any): StrategyMap {
        return new StrategyMap(
            o['id'],
            o['name'],
            o['percentFulfilled'],
            new Date(o['startDate']),
            new Date(o['endDate']),
            o['dashboardId'],
            o['active'],
            o['proposalValues'] || [],
            o['strategyMapObjetives'],
            new Date(o['updateDate']),
            new Date(o['registerDate']),
            o['vision'],
            o['mision'],
            o['values'],
        );
    }

    public get lastUpdated(): Date {
        return this.updateDate || this.registerDate;
    }

    constructor(
        public id:                      number,
        public name:                    string,
        public percentFulfilled:        number,
        public startDate:               Date,
        public endDate:                 Date,
        public dashboardId?:            number,
        public active?:                 boolean,
        public proposalValues?:         ProposalValue[],
        public strategyMapObjetives?:   Objective[],
        public updateDate?:             Date,
        public registerDate?:           Date,
        public vision?:                 string,
        public mision?:                 string,
        public values?:                 string,
    ) { }
}
